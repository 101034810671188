/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { PageProps } from "gatsby"
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout"
import Seo from "@lekoarts/gatsby-theme-jodie/src/components/seo"
import GridItem from "@lekoarts/gatsby-theme-jodie/src/components/grid-item"
import GridGroup from "./grid-group"
import locales from "@lekoarts/gatsby-theme-jodie/src/locales"
import { visuallyHidden } from "@lekoarts/gatsby-theme-jodie/src/styles/utils"

type DataProps = {
  projects: {
    nodes: {
      shortTitle: string
      slug: string
      cover: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }[]
  }
}

const Project: React.FC<PageProps<DataProps>> = ({ data: { projects }, location }) => {
  return (
    <Layout>
      <Seo title="Projects" pathname={location.pathname} />
      <h1 sx={visuallyHidden} data-testid="page-title">
        {locales.projects}
      </h1>
      <div
      >
        <h2>Who are we?</h2>
        <p>We're a small family business working on the scale from illustration and design to secure and data driven web applications.</p>

        <h2>Who we worked with...</h2>
        <p>Our clients range from smaller business to big corporations. We had the chance to work with OLX Group, Letgo, CRISP Studio, Mayday, CSI Payment Solutions, The Alchemist Atelier, Born Rosé, Online SOS, Blue Link Stories.</p>
      </div>
    </Layout>
  )}

export default Project
